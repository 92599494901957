<template>
  <div id="cont">
    <div>
      <Menu />
    </div>
    <div>
      <router-view />
    </div>
    <div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Menu from './components/Menu.vue';
import Footer from './components/Footer.vue';

export default {
  components: {
    Menu,
    Footer
  },
};
</script>

<style>
body {
  background: rgb(203, 234, 255);
  background: linear-gradient(135deg, rgba(203, 234, 255, 1) 0%, rgba(125, 203, 255, 1) 100%);
  background-attachment: fixed;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding-top: 100px;
}

@media only screen and (max-width: 880px) {
  #app {
    padding-top: 0px;
  }
}
</style>
