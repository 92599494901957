<template>
    <div v-if="!isMobileWidth" :class="{ 'menu-container': true, 'sticky': isSticky }">
        <div class="name">
            <img class="logo" src="../assets/logo.png" alt="logo" width="250" height="30" />
        </div>
        <div class="menu">
            <a href="#introduction">Predstavitev</a>
            <a href="#features">Funkcije</a>
            <a href="#zakaj">Zakaj prijaviure.si</a>
            <a href="#contact">Kontakt</a>
            <div class="menu-item" @click="redirectTo">Aplikacija</div>
        </div>
    </div>
    <div v-else class="mobile-menu-button">
        <div style="font-size:40px;cursor:pointer" @click="openNav">&#9776;</div>
    </div>
    <!-- Mobile Menu -->
    <div id="mySidenav" :class="{ 'mobile-menu': true, 'mobile-menu-open': isMobileMenuOpen, 'sidenav': true }"
        :style="{ width: sidenavWidth + 'px' }">
        <a href="javascript:void(0)" class="closebtn" @click="closeNav">&times;</a>
        <a href=" #introduction" @click="closeNav">Predstavitev</a>
        <a href="#features" @click="closeNav">Funkcije</a>
        <a href="#zakaj" @click="closeNav">Zakaj prijaviure.si</a>
        <a href="#contact" @click="closeNav">Kontakt</a>
        <div class="menu-item" @click="redirectTo">Aplikacija</div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            isSticky: false,
            isMobileWidth: false,
            isMobileMenuOpen: false,
            sidenavWidth: 0,
        };
    },
    methods: {
        redirectTo() {
            window.location.href = 'https://app.prijaviure.si';
        },
        handleScroll() {
            this.isSticky = window.scrollY > 0;
        },
        checkScreenWidth() {
            if (window.innerWidth < 1050) {
                this.isMobileWidth = true
            } else {
                this.isMobileWidth = false
            }
        },
        openNav() {
            this.sidenavWidth = 250;
        },
        closeNav() {
            this.sidenavWidth = 0;
        }
    },
    mounted() {
        this.checkScreenWidth()
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.checkScreenWidth);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.checkScreenWidth);
    },
};
</script>
  
<style scoped>
.menu-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    margin: 10px;
    position: fixed;
    width: 98vw;
    top: 0;
    transition: background-color 0.3s, box-shadow 0.3s, padding 0.3s;
    z-index: 1000;
}

.sticky {
    background-color: #3498db6e;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 5rem;
}

.menu {
    display: flex;
    background-color: #ecf0f1;
    border-radius: 5rem;
}

.menu a,
.menu-item {
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
    padding: 10px;
    margin: 10px;
    transition: color 0.3s;
    display: inline-block;
}

.menu-item {
    color: #ecf0f1;
    background-color: #3498db;
    border-radius: 5rem;
    cursor: pointer;
}

.menu a {
    color: #2c3e50;
}

.menu a:hover {
    color: #2c3e50d0;
}

.logo {
    width: 250px;
    height: 30px;
    transition: width 0.3s, height 0.3s;
}

.sticky .logo {
    width: 200px;
    height: 24px;
}

.sidenav {
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #111111ea;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    padding-bottom: 10px;
    border-bottom-right-radius: 10px;
}

.sidenav a {
    padding: 8px 8px 8px 8px;
    text-decoration: none;
    font-size: 25px;
    color: #b6b6b6;
    display: block;
    transition: 0.3s;
}

.sidenav a:hover {
    color: #f1f1f1;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

.mobile-menu-button {
    text-align: left;
    position: fixed;
    top: 5px;
    left: 5px;
    z-index: 9999;
}
</style>