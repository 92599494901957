<template>
  <div class="">
    <div id="introduction">
      <div class="col">
        <div class="contact-inner" style="max-width: 500px; margin: 0px 10px">
          <h1>Poenostavite evidentiranje delovnega časa</h1>
          <h3>Od posameznikov do podjetij - enostavno, hitro, zanesljivo</h3>
          <p>
            Sledenje delovnega časa za svojo ekipo ali osebne projekte z našo
            uporabnikom prijazno aplikacijo.<br />
            Od prijave do vpogledov - enostavno upravljanje delovnih ur.
          </p>
        </div>
      </div>
      <div class="col" v-if="!isNarrow">
        <img
          src="../assets/1.jpg"
          alt="logo"
          width="550"
          style="border-radius: 10px"
        />
      </div>
    </div>
    <div id="features" style="padding: 0px 20px">
      <p
        style="
          margin-top: 100px;
          margin-bottom: 60px;
          font-size: 2.5rem;
          font-weight: bold;
        "
      >
        Funkcije
      </p>
      <div class="cards">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title font-weight-bold">Beleženje rednih ur</h4>
            <p class="card-text">
              Preprosto spremljajte svoj redni delovni čas s preprostim in
              intuitivnim vmesnikom.
            </p>
            <p>
              Vnesite začetni in končni čas, da zagotovite točen zapis časa.
            </p>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4 class="card-title font-weight-bold">Čas kosila in odmora</h4>
            <p class="card-text">
              Nemoteno beležite odmore za kosilo in druge odmore skozi ves
              delovni dan.
            </p>
            <p class="card-text">
              Ohranite jasen pregled nad svojim dnevnim urnikom in
              produktivnostjo.
            </p>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4 class="card-title font-weight-bold">
              Dopust in bolniška odsotnost
            </h4>
            <p class="card-text">
              Z lahkoto načrtujte in upravljajte prosti čas. Vnaprej vnesite
              načrtovani dopust.
            </p>
            <p class="card-text">
              Takoj si oglejte svoje nabrane dni dopusta in preostalo bolniško
              odsotnost.
            </p>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4 class="card-title font-weight-bold">Prenos tabele</h4>
            <p class="card-text">
              Preprosto izvozite podatke o delovnem času. Prenesite tabelo svoje
              evidence.
            </p>
            <p class="card-text">
              Vodite celovito evidenco svojih delovnih ur za osebno ali poklicno
              uporabo.
            </p>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4 class="card-title font-weight-bold">Prihaja več funkcij</h4>
            <p class="card-text">
              Spremljajte posodobitve aplikacije za dodatne funkcije z namenom
              izboljšanja vaše izkušnje.
            </p>
            <p class="card-text">
              V primeru, da že uporabljate našo aplikacijo vas bomo o novih
              funkcijah obveščali po e-pošti.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div id="features-mobile" style="padding: 0px 20px">
      <p
        style="
          margin-top: 100px;
          margin-bottom: 60px;
          font-size: 2.5rem;
          font-weight: bold;
        "
      >
        Funkcije
      </p>
      <div class="row">
        <div class="col feat-mob">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title font-weight-bold">Beleženje rednih ur</h4>
              <p class="card-text">
                Preprosto spremljajte svoj redni delovni čas s preprostim in
                intuitivnim vmesnikom.
              </p>
              <p>
                Vnesite začetni in končni čas, da zagotovite točen zapis časa.
              </p>
            </div>
          </div>
        </div>
        <div class="col feat-mob">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title font-weight-bold">Čas kosila in odmora</h4>
              <p class="card-text">
                Nemoteno beležite odmore za kosilo in druge odmore skozi ves
                delovni dan.
              </p>
              <p class="card-text">
                Ohranite jasen pregled nad svojim dnevnim urnikom in
                produktivnostjo.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col feat-mob">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title font-weight-bold">
                Dopust in bolniška odsotnost
              </h4>
              <p class="card-text">
                Z lahkoto načrtujte in upravljajte prosti čas. Vnaprej vnesite
                načrtovani dopust.
              </p>
              <p class="card-text">
                Takoj si oglejte svoje nabrane dni dopusta in preostalo bolniško
                odsotnost.
              </p>
            </div>
          </div>
        </div>
        <div class="col feat-mob">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title font-weight-bold">Prenos tabele</h4>
              <p class="card-text">
                Preprosto izvozite podatke o delovnem času. Prenesite tabelo
                svoje evidence.
              </p>
              <p class="card-text">
                Vodite celovito evidenco svojih delovnih ur za osebno ali
                poklicno uporabo.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col feat-mob">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title font-weight-bold">Prihaja več funkcij</h4>
              <p class="card-text">
                Spremljajte psadsaosodobitve aplikacije za dodatne funkcije z
                namenom izboljšanja vaše izkušnje.
              </p>
              <p class="card-text">
                V primeru, da že uporabljate našo aplikacijo vas bomo o novih
                funkcijah obveščali po e-pošti.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin-top: 150px" id="zakaj">
      <div class="col">
        <p style="font-size: 3rem; font-weight: bold; margin-bottom: 80px">
          Zakaj prijaviure.si?
        </p>
        <div class="row" style="padding: 0px 20px">
          <div class="col">
            <div class="contact-inner">
              <h3><b>Uporabniku prijazen vmesnik</b></h3>
              <p>
                Aplikacija se ponaša z uporabniku prijazno in preprosto zasnovo,
                ki zagotavlja izkušnjo brez težav. Navigiranje po funkcijah je
                enostavno, kar vam omogoča, da se osredotočite na tisto, kar je
                najpomembnejše – vaše delo.
              </p>
            </div>
          </div>
          <div class="col">
            <div class="contact-inner">
              <h3><b>Prilagodljivost za posameznike:</b></h3>
              <p>
                Brezplačen dostop do vseh funkcij za individualno uporabo.
                Beležite redne ure, odmore, malice ter načrtujte dopust za
                potovanja.
              </p>
            </div>
          </div>
          <div class="col">
            <div class="contact-inner">
              <h3><b>Dodatne funkcije za podjetja:</b></h3>
              <p>
                Nadgradite na naš plačljivi paket, zasnovan za skupine, podjetja
                ter organizacije, da odklenete napredne funkcije. Registrirajte
                se in si s tem pridobite možnost upravljanja ur zaposlenih v
                vaši skupini, podjetju ali organizaciji.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="contact"
      style="margin-top: 150px; background-color: #ecf0f1; padding: 20px 0px"
    >
      <p style="margin-bottom: 40px; font-size: 2.5rem; font-weight: bold">
        Kontakt
      </p>
      <div class="" style="padding: 30px">
        <div class="row">
          <div class="col" style="display: flex; justify-content: center">
            <p style="max-width: 500px">
              Preizkusite brezplačno! Ste pripravljeni prevzeti nadzor nad
              svojim delovnim časom? Prijavite se brezplačno še danes in
              prevzamite upravljanje vašega delovnega časa v vaše roke.
              Spremljajte posodobitve, saj bomo še naprej izboljševali našo
              aplikacijo z novimi funkcijami, da bo vaše delovno življenje še
              bolj produktivno!
            </p>
          </div>
        </div>
        <div style="display: flex; justify-content: center; margin-top: 40px">
          <button @click="redirectTo" class="btn button-register">
            Preizkusite brezplačno
          </button>
          <span style="width: 100px"></span>
          <button class="btn button-contact">
            <a style="text-decoration: none" href="mailto:info@prijaviure.si"
              >Kontaktirajte nas</a
            >
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isNarrow: false,
    };
  },
  mounted() {
    this.checkWidth();
    window.addEventListener("resize", this.checkWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkWidth);
  },
  methods: {
    checkWidth() {
      if (window.innerWidth < 1050) {
        this.isNarrow = true;
      } else {
        this.isNarrow = false;
      }
    },
    redirectTo() {
      window.location.href = "http://app.prijaviure.si/";
    },
  },
};
</script>

<style>
#introduction {
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#features-mobile {
  display: none;
}

.cards {
  overflow-x: auto;
  display: flex;
}

.card {
  margin-right: 10px;
  display: flex;
  min-width: 350px;
  text-align: center;
  background-color: rgb(226, 243, 255);
  border: none;
  border-radius: 5rem;
  padding: 20px 5px;
}

.card-title {
  color: #3498db;
}

.contact-inner {
  display: inline-block;
  background-color: rgb(203, 234, 255);
  border-radius: 5rem;
  padding: 20px;
  box-shadow: 0px 0px 5px 1px lightslategray;
}

.button-register {
  background-color: #3498db;
  color: #ecf0f1;
  font-size: 20px;
}

.button-register:hover {
  background-color: #2491da;
  color: #ecf0f1;
}

.button-contact {
  background-color: #4cb963;
  font-size: 20px;
}

.button-contact a {
  color: #ecf0f1;
}

.button-contact:hover {
  background-color: #36bd53;
}

@media only screen and (max-width: 880px) {
  #app {
    padding-top: 0px;
  }

  .card {
    width: 300px;
    margin-bottom: 10px;
  }

  #features {
    display: none;
  }

  #features-mobile {
    display: block;
  }

  .feat-mob {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 500px) {
  .card {
    width: 100%;
    margin-right: 0px;
  }

  .contact-inner {
    margin-bottom: 10px;
  }
}
</style>
